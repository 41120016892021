import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    // Button
} from 'react-bootstrap'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFacebookSquare, faTwitterSquare, faInstagramSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'
import "../css/footer.css"

class Footer extends Component {
    render() {
        return (
            <>
                <div className="footer-wrapper">
                    <Container style={{ padding: "30px" }}>
                        <Row>
                            <Col sm={12} md={12} lg={4} xl={4}>
                                <p>Maxi Pack Manuseio e Embalagens Ltda</p>
                                <p>Rua Tavares, 261 - Jardim São Sebastião</p>
                                <p>Jaguariúna - SP</p>
                                <p>CEP 13917-172</p>
                                <p>&nbsp;</p>
                                <p>Tel: 19-3837-3736 - 19-3837-3787</p>
                                <p>maxipack@maxipack.com.br</p>
                            </Col>
                            {/* <Col sm={12} md={12} lg={4} xl={4} />
                            <Col sm={12} md={12} lg={4} xl={4} className="icons-wrapper">
                                <div className="social-button-conteiner">
                                    <div className="social-button ok">
                                        <Button href="#" className="shadow-none">
                                            <FontAwesomeIcon icon={faFacebookSquare} className="footer-icon" />
                                        </Button>
                                    </div>
                                </div>
                                <div className="social-button-conteiner">
                                    <div className="social-button">
                                        <Button href="#" className="shadow-none">
                                            <FontAwesomeIcon icon={faTwitterSquare} className="footer-icon" />
                                        </Button>
                                    </div>
                                </div>
                                <div className="social-button-conteiner">
                                    <div className="social-button">
                                        <Button href="#" className="shadow-none">
                                            <FontAwesomeIcon icon={faInstagramSquare} className="footer-icon" />
                                        </Button>
                                    </div>
                                </div>
                                <div className="social-button-conteiner">
                                    <div className="social-button">
                                        <Button href="#" className="shadow-none">
                                            <FontAwesomeIcon icon={faYoutubeSquare} className="footer-icon" />
                                        </Button>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col sm={4} />
                            <Col sm={4} style={{textAlign:"center", paddingTop:"30px"}}>
                                <p>© Maxi Pack -  {(new Date().getFullYear())}</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default Footer