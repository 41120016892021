import React from 'react'
import { window } from "browser-monads";
import {
    Nav,
    Navbar,
    Container,
} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/navbar.css'

class MainNavbar extends React.Component {

    constructor(props) {
        super(props);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onFoucs = this.onFocus.bind(this);
        this.state = {
            collapseOpen: false,
            color: "navbar-transparent",
            dropdownOpen: false,
            mobile: (window.innerWidth < 992) ? true : false,
        };
    }
    componentDidMount() {
        if (this.state.mobile) {
            this.setState({
                dropdownOpen: true
            });
        }
        window.addEventListener("scroll", this.changeColor);
    }
    onMouseEnter() {
        if (!this.state.mobile) {
            this.setState({ dropdownOpen: true });
        }
    }

    onMouseLeave() {
        if (!this.state.mobile) {
            this.setState({ dropdownOpen: false });
        }
    }

    onFocus() { }

    render() {
        return (
            <Navbar expand="lg" bg="light" variant="mp">
                <Container style={{ alignItems: "flex-start" }}>
                        <Navbar.Brand href="/">
                            <img
                                src={require("../images/logo-maxipack-alpha.svg")}
                                className="d-inline-block align-top main-logo"
                                alt="Maxipack"
                            />
                        </Navbar.Brand>

                        <Navbar.Toggle className="navbar-toggler"/>
                        <Navbar.Collapse  className="justify-content-end">
                            <Nav >
                                <Nav.Link href="/servicos">Serviços</Nav.Link>
                                <Nav.Link href="/qualidade">Qualidade</Nav.Link>
                                {/* <ul className="nav-dropdown" 
                                role="presentation"
                                onMouseOver={this.onMouseEnter} onFocus={this.onFocus} onMouseLeave={this.onMouseLeave}
                            >
                                <Nav.Link href="#graphic-design" className="nav-main">Graphic design</Nav.Link>
                                <ul className="nav-sub" style={{ display: this.state.dropdownOpen ? 'block' : 'none' }}>
                                    <li className="">
                                        <Dropdown.Item
                                            className="nav-link"
                                            href="/graphic-design-logos"
                                            rel="noopener noreferrer">
                                            <span className={"customnav"}>Logo design</span>
                                        </Dropdown.Item>
                                    </li>
                                    <li className="p-0 nav-subitem">
                                        <Dropdown.Item
                                            className="nav-link"
                                            href="/graphic-design-social-network-arts"
                                            rel="noopener noreferrer">
                                            <span className={"customnav"}>Social network arts</span>
                                        </Dropdown.Item>
                                    </li>
                                    <li className="p-0 nav-subitem">
                                        <Dropdown.Item
                                            className="nav-link"
                                            href="/graphic-design"
                                            rel="noopener noreferrer">
                                            <span className={"customnav"}>Printed</span>
                                        </Dropdown.Item>
                                    </li>
                                </ul>
                            </ul> */}
                                <Nav.Link href="/sobre-nos">Sobre nós</Nav.Link>
                                <Nav.Link href="/contato">Contato</Nav.Link>
                                <Nav.Link href="/canal-de-conduta-etica">Canal de Conduta Ética</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default MainNavbar