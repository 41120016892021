import React from "react"
import Helmet from "react-helmet"


function Metatags({ title, description, url, image, keywords }) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta name="robots" content="index, follow" />
            <meta name="Generator" content="Netbistrot serverless" />
            <meta name="keywords" content={keywords} />

            <link rel="apple-touch-icon-precomposed" sizes="57x57" href="../images/apple-touch-icon-57x57.png" />
            <link rel="apple-touch-icon-precomposed" sizes="114x114" href="../images/apple-touch-icon-114x114.png" />
            <link rel="apple-touch-icon-precomposed" sizes="72x72" href="../images/apple-touch-icon-72x72.png" />
            <link rel="apple-touch-icon-precomposed" sizes="144x144" href="../images/apple-touch-icon-144x144.png" />
            <link rel="apple-touch-icon-precomposed" sizes="60x60" href="../images/apple-touch-icon-60x60.png" />
            <link rel="apple-touch-icon-precomposed" sizes="120x120" href="../images/apple-touch-icon-120x120.png" />
            <link rel="apple-touch-icon-precomposed" sizes="76x76" href="../images/apple-touch-icon-76x76.png" />
            <link rel="apple-touch-icon-precomposed" sizes="152x152" href="../images/apple-touch-icon-152x152.png" />
            <link rel="icon" type="image/png" href="../images/favicon-196x196.png" sizes="196x196" />
            <link rel="icon" type="image/png" href="../images/favicon-96x96.png" sizes="96x96" />
            <link rel="icon" type="image/png" href="../images/favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="../images/favicon-16x16.png" sizes="16x16" />
            <link rel="icon" type="image/png" href="../images/favicon-128.png" sizes="128x128" />
            <meta name="application-name" content="&nbsp;" />
            <meta name="msapplication-TileColor" content="#009036" />
            <meta name="msapplication-TileImage" content="../images/mstile-144x144.png" />
            <meta name="msapplication-square70x70logo" content="../images/mstile-70x70.png" />
            <meta name="msapplication-square150x150logo" content="../images/mstile-150x150.png" />
            <meta name="msapplication-wide310x150logo" content="../images/mstile-310x150.png" />
            <meta name="msapplication-square310x310logo" content="../images/mstile-310x310.png" />

            <link hrefLang="pt-BR" href={url} />
            <link rel="canonical" href={url} />
            <link rel="shortlink" href={url} />
            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content="NetBistrot" />
            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={image} />
        </Helmet>
    )
}

export default Metatags;